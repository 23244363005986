* {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
}

* {
  box-sizing: border-box;
}

body {
  font-family: Arial;
  /* padding: 20px; */
  background: #edf6f9;
  padding-top: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.card {
  background-color: white;
  color: #000;
  padding: 20px;
  margin-top: 20px;
  border-radius: 10px;
  font-size: 16px;
  line-break: auto;
  word-spacing: 4px;
  line-height: 30px;
}

/*====================== 404 page =======================*/

.page_404 {
  margin: 10px;
  background: #fff;
  font-family: "Arvo", serif;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 400px;
  background-position: center;
}

.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.contant_box_404 {
  margin-top: -50px;
}
